import { template as template_babf2fb5afc44bfba4241893c66f491e } from "@ember/template-compiler";
const FKText = template_babf2fb5afc44bfba4241893c66f491e(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
