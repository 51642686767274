import { template as template_77a8c711fbe24da6898ad8e74939e0ab } from "@ember/template-compiler";
const FKLabel = template_77a8c711fbe24da6898ad8e74939e0ab(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
